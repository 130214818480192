import axios from 'axios'
import { omit } from 'lodash'

import { CreateSavedFilterPayload, DeleteResponse, SavedFilter } from './types'
import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import { addTimeToDate, handleAxiosError } from '../../api/utils'
import { RequireKeys } from '../../utils/types'

export const fetchSavedFilters = (customerId: string) => {
  const url = `${denaliApiBaseUrl()}/v2/account/saved-queries?customerId=${customerId}`

  return axios
    .get<{ results: SavedFilter[] }>(url, { headers: defaultHeaders() })
    .then(({ data }) => data.results)
    .catch(handleAxiosError)
}

export const createSavedFilter = (payload: CreateSavedFilterPayload) => {
  const url = `${denaliApiBaseUrl()}/v2/account/saved-queries`
  const params = {
    ...payload,
    parameters: {
      query: {
        ...payload.parameters.query,
        toDate: addTimeToDate(payload.parameters.query.toDate, 'T23:59:59'),
        fromDate: addTimeToDate(payload.parameters.query.fromDate),
      },
    },
  }
  return axios
    .post<SavedFilter>(url, params, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}

export const updateSavedFilter = (payload: RequireKeys<SavedFilter, 'id'>) => {
  const url = `${denaliApiBaseUrl()}/v2/account/saved-queries/${payload.id}`

  return axios
    .patch<SavedFilter>(url, omit(payload, 'id'), { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}

export const deleteSavedFilter = (id: string) => {
  const url = `${denaliApiBaseUrl()}/v2/account/saved-queries/${id}`

  return axios
    .delete<DeleteResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}
