import {
  demoUserBenchmarkingMapper,
  demoUserCustomerMappedPayload,
} from './demoUserMapper'
import { formatNumberWithCustomFormatting } from '../../../utils'
import { isCurrentUserRedaptiveDemoUser } from '../../../utils/demo'
import {
  ConnectPropertyMapped,
  TSBenchmarkingCardData,
  TSBenchmarkingDataMapped,
  TSBenchmarkingDataOriginal,
  TSEnergyStarSitesCount,
  TSEnergyStarSitesCountMapped,
  TSPendingPropertySite,
} from '../types'

export const WPT_CUSTOMER_ID = '938bd255-a9f3-4d11-888d-af68d8e37e4b'

export const ES_DATE_FORMAT = 'MM/DD/YYYY'

export const customerStatusMapper = (
  data: TSEnergyStarSitesCount,
  customerId: string
): TSEnergyStarSitesCountMapped => {
  if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
    return demoUserCustomerMappedPayload()
  }
  return {
    isEnabled: data.integrationEnabled,
    energyStarCustomerId: data.energyStarCustomerId,
    siteMappingEnabled: data.siteMappingEnabled,
    sitesCount: {
      electric: data.enabledElectricSites,
      water: data.enabledWaterSites,
      gas: data.enabledNaturalGasSites,
    },
  }
}

export const benchmarkingMapper = (
  data: TSBenchmarkingDataOriginal[],
  customerId: string,
  unitFormatPreference: string
): {
  mappedData: TSBenchmarkingDataMapped[]
  cardData: TSBenchmarkingCardData
} => {
  const checkValueAndFormat = (value: number) => {
    if (value) {
      return formatNumberWithCustomFormatting(value, unitFormatPreference)
    }
    return value?.toString()
  }

  let mappedData: TSBenchmarkingDataMapped[] = data
    .sort((a, b) => b.siteName.localeCompare(a.siteName))
    .map((item) => {
      return {
        id: item.id,
        siteEui: checkValueAndFormat(item.siteEui),
        sourceEui: checkValueAndFormat(item.sourceEui),
        siteName: item.siteName,
        nationalMedianSiteEui: checkValueAndFormat(item.nationalMedianSiteEui),
        nationalMedianSourceEui: checkValueAndFormat(
          item.nationalMedianSourceEui
        ),
        nationalMedianSiteEuiDiff: checkValueAndFormat(
          item.nationalMedianSiteEuiDiff
        ),
        nationalMedianSourceEuiDiff: checkValueAndFormat(
          item.nationalMedianSourceEuiDiff
        ),
        energyStarScore: checkValueAndFormat(item.energyStarScore),
        eligibleForCertification:
          item.eligibleForCertification.toLocaleLowerCase() === 'yes'
            ? 'Yes'
            : 'No',
        totalGhgEmissions: checkValueAndFormat(item.totalGhgEmissions),
        modified: item.modified,
      }
    })

  const cardData: TSBenchmarkingCardData = {
    siteWithLowestEUI: null,
    siteWithLowestGHGSubmissions: null,
    sitesEligibleForCertification: 0,
  }

  if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
    const demoData = demoUserBenchmarkingMapper(
      mappedData,
      unitFormatPreference
    )
    mappedData = structuredClone(demoData.mappedData)
  }

  let siteWithLowestEUI: TSBenchmarkingDataMapped | null = null
  let siteWithLowestGHGSubmissions: TSBenchmarkingDataMapped | null = null

  mappedData.forEach((site) => {
    const { siteEui, totalGhgEmissions, eligibleForCertification } = site
    if (siteEui) {
      if (!siteWithLowestEUI) {
        siteWithLowestEUI = site
      } else if (site.siteEui < siteWithLowestEUI.siteEui) {
        siteWithLowestEUI = site
      }
      cardData.siteWithLowestEUI = siteWithLowestEUI.siteName
    }

    if (totalGhgEmissions) {
      if (!siteWithLowestGHGSubmissions) {
        siteWithLowestGHGSubmissions = site
      } else if (
        site.totalGhgEmissions < siteWithLowestGHGSubmissions.totalGhgEmissions
      ) {
        siteWithLowestGHGSubmissions = site
      }
      cardData.siteWithLowestGHGSubmissions =
        siteWithLowestGHGSubmissions.siteName
    }

    if (eligibleForCertification?.toLocaleLowerCase() === 'yes') {
      if (!cardData.sitesEligibleForCertification) {
        cardData.sitesEligibleForCertification = 1
      } else {
        cardData.sitesEligibleForCertification += 1
      }
    }
  })

  return {
    mappedData,
    cardData,
  }
}

export const espmMeterMapper = (
  data: TSPendingPropertySite[]
): ConnectPropertyMapped[] => {
  return data
    .sort((a, b) => a.siteName.localeCompare(b.siteName))
    .map(({ siteName, rdpSiteId, esPropertyId, esPropertyName, esMeters }) => {
      const electricMeter = esMeters.find(
        (meter) => meter.esMeterType === 'electricity'
      )
      const gasMeter = esMeters.find(
        (meter) => meter.esMeterType === 'naturalGas'
      )
      const waterMeter = esMeters.find((meter) => meter.esMeterType === 'water')
      let esPropertyLabel = esPropertyName
      if (esPropertyId?.toString() && !esPropertyLabel) {
        esPropertyLabel = siteName
      }
      return {
        siteName,
        rdpSiteId,
        esPropertyData: {
          value: esPropertyId?.toString(),
          label: esPropertyLabel,
          isDisabled: !!esPropertyId,
        },
        electricity: {
          isChecked: electricMeter?.esMeterActive ?? false,
          isDisabled:
            (!electricMeter?.esMeterEnabled || electricMeter?.esMeterActive) ??
            true,
          esMeterId: electricMeter?.esMeterId,
        },
        water: {
          isChecked: waterMeter?.esMeterActive ?? false,
          isDisabled:
            (!waterMeter?.esMeterEnabled || waterMeter?.esMeterActive) ?? true,
          esMeterId: waterMeter?.esMeterId,
        },
        naturalGas: {
          isChecked: gasMeter?.esMeterActive ?? false,
          isDisabled:
            (!gasMeter?.esMeterEnabled || gasMeter?.esMeterActive) ?? true,
          esMeterId: gasMeter?.esMeterId,
        },
      }
    })
    .filter(
      (item) =>
        !(
          item.electricity.isDisabled &&
          item.water.isDisabled &&
          item.naturalGas.isDisabled
        )
    )
}

export const espmMeterResponseMapper = (
  data: ConnectPropertyMapped[],
  customerId: string
) => {
  return data.map((item) => {
    const getResponseMeter = (item, key) => {
      return {
        esMeterType: key,
        esMeterEnabled: !item[key].isDisabled,
        esMeterActive: item[key].isChecked,
        esMeterId: item[key].esMeterId,
      }
    }

    return {
      siteName: item.siteName,
      rdpCustomerId: customerId,
      rdpSiteId: item.rdpSiteId,
      esPropertyName: item?.esPropertyData?.label,
      esPropertyId: item?.esPropertyData?.value,
      esMeters: [
        getResponseMeter(item, 'electricity'),
        getResponseMeter(item, 'water'),
        getResponseMeter(item, 'naturalGas'),
      ],
    }
  })
}
