import { Group, Stack } from '@mantine/core'
import { isEmpty, omit } from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import MultiSelect from '../../../components/mantine/MultiSelect'
import { DataExplorerFilterParams } from '../types'
import { useMetadataFieldsWithoutSites } from './filterUtils'
import styles from './index.module.css'
import { legacyFilterKeys } from '../utils/searchParams'

type FilterProps = {
  data: { value: string; label: string }[]
  disabled?: boolean
  fieldName: string
  name: string
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>
  values: string[]
}

const Filter = ({
  data,
  disabled,
  fieldName,
  name,
  setPendingFilters,
  values,
}: FilterProps) => {
  const [tempValues, setTempValues] = useState<string[] | undefined>(values)
  const setValues = useCallback(() => {
    if (isEmpty(tempValues)) {
      setPendingFilters(
        (prev) => omit(prev, fieldName) as DataExplorerFilterParams
      )
    } else {
      setPendingFilters((prev) => ({
        ...prev,
        [fieldName]: tempValues,
      }))
    }
  }, [setPendingFilters, fieldName, tempValues])
  const onClear = useCallback(() => {
    setTempValues(undefined)
    setPendingFilters(
      (prev) => omit(prev, fieldName) as DataExplorerFilterParams
    )
  }, [fieldName, setPendingFilters])

  // If values change externally, update internal state
  useEffect(() => {
    setTempValues(values)
  }, [values])

  return (
    <MultiSelect
      data={data}
      data-gainsight-id={`sites-query-advanced-filters-selector-${fieldName}`}
      disabled={disabled}
      key={fieldName}
      label={name}
      limit={100}
      maw={220}
      maxDisplayedValues={1}
      onClear={onClear}
      onDropdownClose={setValues}
      pillMaxWidth={105}
      placeholder='—'
      rightSectionWidth={30}
      setValues={setTempValues}
      size='md'
      values={tempValues}
      w={180}
      withPillRemoveButton={false}
    />
  )
}

type AdvancedFiltersProps = {
  pendingFilters: DataExplorerFilterParams
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>
  visible: boolean
}

const AdvancedFilters = ({
  pendingFilters,
  setPendingFilters,
  visible,
}: AdvancedFiltersProps) => {
  const { data: fieldsWithoutSites, ...metadataQuery } =
    useMetadataFieldsWithoutSites(pendingFilters)

  const pluralizedFieldNames = fieldsWithoutSites.map(
    ({ fieldName, ...rest }) => {
      const pluralizedFieldname = legacyFilterKeys.includes(fieldName)
        ? `${fieldName}Ids`
        : fieldName
      return { ...rest, fieldName: pluralizedFieldname }
    }
  )

  return (
    <Stack w='100%' pos='relative'>
      <Group
        className={styles.dropdown}
        display={visible ? 'flex' : 'none'}
        p={0}
        align='flex-end'
      >
        <Group p='12px 20px 20px 20px' gap='4px 12px' align='flex-end'>
          {pluralizedFieldNames.map(({ fieldName, options, name }) => (
            <Filter
              data={options}
              disabled={metadataQuery.isLoading}
              fieldName={fieldName}
              key={fieldName}
              name={name}
              setPendingFilters={setPendingFilters}
              values={pendingFilters[fieldName]}
            />
          ))}
        </Group>
      </Group>
    </Stack>
  )
}

export default AdvancedFilters
