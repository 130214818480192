import { useFormik } from 'formik'
import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { getUsername } from '../../../api/utils'
import { actions as modalActions } from '../../../ducks/modal'
import { useEnableEnergyStarPopupStore } from '../../../globalState/energyStar'
import {
  useEnergyStarSitesCountQuery,
  useOnboardingAccountDataQuery,
  useUpdateEnergyStarCustomerMutation,
} from '../../../queries/energyStar'
import { energyStarApis } from '../../../queries/energyStar/apis'
import { slugify } from '../../../utils'
import Button from '../../mantine/Button'
import ModalCustom from '../../mantine/Modal'
import showCustomNotification from '../../mantine/Notifications'
import AccountIdErrorModal from '../AccountIdErrorModal'
import Authenticate from '../OnboardingSteps/Authenticate'
import Confirmation from '../OnboardingSteps/Confirmation'
import IdentifyModal from '../OnboardingSteps/Identify'
import Stepper, { StepperStep } from '../Stepper/Stepper'

export const MainContainer = styled.div<{ bottomPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px;
  text-align: center;
  ${({ bottomPadding }) => bottomPadding && `padding-bottom:50px;`}
`

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background: #f0f0f1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px;
`

const Heading = styled.div`
  color: #162447;
  font-size: 28px;
  font-weight: 700;
`

export const SubModalHeading = styled.div`
  color: #162447;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const SubModalSubHeading = styled.div`
  color: #162447;
  font-size: 13px;
  font-weight: 400;
`

const StepperContainer = styled.div`
  margin: 35px 0px;
  width: 100%;
`

const SubHeading = styled.div``

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentSubContainer = styled.div`
  text-align: left;
  width: 70%;
  margin: 20px;
`

export const getEnableCustomerPayload = ({
  energyStarId,
  siteMappingEnabled,
}: {
  energyStarId: string
  siteMappingEnabled: boolean | null
}) => {
  return {
    enabled: true,
    sendMonthlyReport: true,
    emailId: getUsername(),
    energyStarId,
    siteMappingEnabled,
  }
}

const stepperSteps: StepperStep[] = [
  {
    label: 'Step 1',
    description: 'Indentify',
    content: (
      <ContentContainer>
        <ContentSubContainer>
          <IdentifyModal />
        </ContentSubContainer>
      </ContentContainer>
    ),
  },
  {
    label: 'Step 2',
    description: 'Authenticate',
    content: (
      <ContentContainer>
        <ContentSubContainer>
          <Authenticate />
        </ContentSubContainer>
      </ContentContainer>
    ),
  },
  {
    label: 'Step 3',
    description: 'Confirmation',
    content: (
      <ContentContainer>
        <ContentSubContainer>
          <Confirmation />
        </ContentSubContainer>
      </ContentContainer>
    ),
  },
]

type StateTuple<T> = [T, Dispatch<SetStateAction<T>>] | null

const defaultContextValue = {
  loadingState: null as StateTuple<boolean>,
  activeStepState: null as StateTuple<number>,
  formik: null as any,
}

export const ESOnboardingContext = createContext(defaultContextValue)
export const BACKEND_ERROR = 'BACKEND_ERROR'

const getValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return Yup.object({
        accountId: Yup.number().required('Account ID is required').min(1),
      })
    case 1:
      return Yup.object({
        accountId: Yup.number().required('Account ID is required'),
        email: Yup.string()
          .trim()
          .email('Please enter a valid email')
          .required('Email is required'),
        username: Yup.string().trim().required('Username is required'),
      })
    default:
      return Yup.object({})
  }
}

const Layout = () => {
  const esSitesCountQuery = useEnergyStarSitesCountQuery()
  const esCustomerUpdateMutation = useUpdateEnergyStarCustomerMutation()
  const onboardingDataQuery = useOnboardingAccountDataQuery()

  const { dismissEnableEnergyStarPopup } = useEnableEnergyStarPopupStore()

  const [activeStep, setActiveStep] = useState<number>(0)
  const [isWrongAccountIdError, setIsWrongAccountIdError] =
    useState<boolean>(false)
  const dispatch = useDispatch()

  const handleOnClose = async () => {
    dismissEnableEnergyStarPopup()
    dispatch(modalActions.hideModal())
    if (activeStep === 2) {
      await esCustomerUpdateMutation.mutateAsync(
        getEnableCustomerPayload({
          energyStarId: formik?.values?.accountId,
          siteMappingEnabled: true,
        })
      )
      showCustomNotification({
        title: 'Redaptive will enable Properties for you',
        color: 'green',
        message: 'This action may require a few days to complete.',
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      accountId: '',
      email: '',
      username: '',
    },
    validationSchema: getValidationSchema(activeStep),
    onSubmit: () => {
      if (activeStep === 2) {
        handleOnClose()
      } else {
        dataCheckApi()
      }
    },
  })

  const isLoading =
    esSitesCountQuery.isPending ||
    esSitesCountQuery.isFetching ||
    esCustomerUpdateMutation.isPending ||
    onboardingDataQuery.isFetching

  const isBackendError = formik?.errors?.username === BACKEND_ERROR

  const enableESCustomer = async () => {
    try {
      await esCustomerUpdateMutation.mutateAsync(
        getEnableCustomerPayload({
          energyStarId: formik?.values?.accountId,
          siteMappingEnabled: null,
        })
      )

      setActiveStep(2)
    } catch {
      showCustomNotification({
        title: 'Error',
        message:
          'Something went wrong while enabling energy star. Please try again',
        color: 'red',
      })
    }
  }

  const dataCheckApi = async () => {
    formik.setSubmitting(true)
    try {
      let params: { accountId: string; username?: string; email?: string } =
        structuredClone(formik.values)
      if (activeStep === 0) {
        params = { accountId: formik.values.accountId }
      }
      const data = await energyStarApis.fetchOnboardingConnectAccount(params)
      const isValid = data.isValid && !data.isAlreadyVerified

      if (activeStep === 0) {
        if (isValid) {
          setActiveStep(1)
          formik.setTouched({ email: false, username: false })
        } else {
          setIsWrongAccountIdError(true)
        }
      }

      if (activeStep === 1) {
        if (isValid) {
          await enableESCustomer()
        } else {
          formik.setFieldError('username', BACKEND_ERROR)
        }
      }
    } catch {
      showCustomNotification({
        title: 'Error',
        message: 'Something went wrong. Please try again',
        color: 'red',
      })
    }
    formik.setSubmitting(false)
  }

  const handleButtonClick = () => {
    if (isWrongAccountIdError) {
      setIsWrongAccountIdError(false)
      formik.resetForm()
      return
    }
    if (formik.errors.username) {
      formik.setFieldError('username', undefined)
      return
    }
    if (formik.isValid) formik.handleSubmit()
  }

  const renderFooterButton = () => {
    let buttonText = 'Next'
    if (isWrongAccountIdError || isBackendError) {
      buttonText = 'Try Again'
    }

    if (activeStep === 2) {
      buttonText = 'Done'
    }
    return (
      <Button
        loading={isLoading}
        size='sm'
        onClick={handleButtonClick}
        gainsightTagId={`onboarding-modal-${slugify(buttonText)}-button`}
        disabled={!(isWrongAccountIdError || isBackendError) && !formik.isValid}
      >
        {buttonText}
      </Button>
    )
  }

  return (
    <ESOnboardingContext.Provider
      value={{
        activeStepState: [activeStep, setActiveStep],
        loadingState: useState(false),
        formik,
      }}
    >
      <ModalCustom
        opened
        onClose={() => {
          if (!isLoading) {
            handleOnClose()
          }
        }}
        size='xl'
        closeOnClickOutside={false}
      >
        <MainContainer bottomPadding>
          {isWrongAccountIdError ? (
            <AccountIdErrorModal />
          ) : (
            <>
              <Heading>Energy Star Portfolio Manager Onboarding</Heading>
              <SubHeading>Let&apos;s get started!</SubHeading>
              <StepperContainer>
                <Stepper steps={stepperSteps} active={activeStep}></Stepper>
              </StepperContainer>
            </>
          )}
        </MainContainer>
        <FooterContainer>{renderFooterButton()}</FooterContainer>
      </ModalCustom>
    </ESOnboardingContext.Provider>
  )
}

export default Layout
