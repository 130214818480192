import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import NewLayout from '../components/NewLayout'
import { isAuthenticated } from '../globalState/session'
import useAppData from '../hooks/useAppData'

const PrivateRoutes = () => {
  useAppData()

  let pathAndSearch = ''
  if (location.pathname) {
    pathAndSearch = `?${location.pathname.slice(1)}`
    if (location.search) pathAndSearch += location.search
  }

  return isAuthenticated() ? (
    <NewLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </NewLayout>
  ) : (
    <Navigate to={`/login${pathAndSearch}`} />
  )
}

export default PrivateRoutes
