import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { max, min, sum } from 'lodash'
import { Moment } from 'moment'

import { defaultHeaders, denaliApiBaseUrl } from '../api'
import { UsageDataResponse } from './resourceUsage'
import { voltageResponse } from '../mockData/singleSiteAnalysis'
import { DataExplorerFilterParams } from '../pages/DataExplorer/types'
import { isVariantActive } from '../utils/variants'
import { filterParamsToQueryStr } from './resourceUsage/utils'
import { enhanceAxiosError } from './utils/enhanceAxiosError'

function fetchSiteVoltageData(
  params: DataExplorerFilterParams,
  programStartDate?: Moment
) {
  if (isVariantActive('mock')) {
    const usageMockData = voltageResponse // make dynamic w/ dates?
    return Promise.resolve(usageMockData)
  }

  const { siteIds } = params
  const queryStr = filterParamsToQueryStr(params, programStartDate)
  const url = `${denaliApiBaseUrl()}/sites/${
    siteIds[0]
  }/data/electricity?${queryStr}`

  return axios
    .get<UsageDataResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(enhanceAxiosError)
}

export const useSiteVoltageData = (
  params: DataExplorerFilterParams,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {}
  return useQuery({
    queryKey: ['siteVoltage', { params }],
    queryFn: () => fetchSiteVoltageData(params),
    enabled: !!params.siteIds.length && enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: ({ data, ts }) => ({
      groups: data.map((group) => ({
        ...group,
        min: min(group.rmsVoltage) ?? 0,
        max: max(group.rmsVoltage) ?? 0,
        average: group.rmsVoltage
          ? sum(group.rmsVoltage) / group.rmsVoltage?.length
          : 0,
      })),
      ts,
    }),
  })
}
