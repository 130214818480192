// eslint-disable-next-line import/order
import 'react-tooltip/dist/react-tooltip.css'
import InfoCircle from '@iconscout/react-unicons/icons/uil-info-circle'
import { kebabCase } from 'lodash'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import { breakpoints } from '../themes'
import Card from './Card'
import Spinner from './Spinner'

const MetricCardTitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
`

const MetricCardSubTitle = styled.div`
  color: #6c6d6e;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 10px;
  font-weight: 400;
  margin-top: -8px;
`

const MetricCardValue = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  overflow: hidden;
  margin: auto 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: ${breakpoints.xl}) {
    font-size: 20px;
  }
`

const CardStyled = styled(Card)`
  position: relative;
`

const InfoCircleStyled = styled(InfoCircle)`
  position: absolute;
  right: 8px;
  top: 8px;
`

const InfoTooltip = styled(Tooltip)`
  max-width: 200px;
  background-color: #162447 !important;
  z-index: 400;
`

const TooltipStyled = styled(Tooltip)`
  z-index: 1;
`

interface Props {
  subtitle?: string | JSX.Element
  title: string
  valueTooltip?: string | JSX.Element
  value: string | React.ReactNode
  isLoading?: boolean
  infoTooltip?: React.ReactNode
}
const MetricCard = ({
  title,
  value,
  subtitle = '',
  isLoading,
  infoTooltip,
  valueTooltip,
}: Props) => {
  const id = kebabCase(title)

  if (isLoading) return <Spinner centered size='sm' />

  return (
    <CardStyled data-testid='metric-card'>
      {infoTooltip && (
        <>
          <InfoCircleStyled
            color='#9D9D9F'
            id={`${id}-info`}
            data-testid='info-icon'
          />
          <InfoTooltip
            opacity={1}
            place='right-start'
            anchorSelect={`#${id}-info`}
          >
            {infoTooltip}
          </InfoTooltip>
        </>
      )}
      <MetricCardTitle>{title}</MetricCardTitle>
      {!!subtitle && <MetricCardSubTitle>{subtitle}</MetricCardSubTitle>}
      <MetricCardValue id={id} data-testid={id}>
        {value}
      </MetricCardValue>

      {typeof value === 'string' && (
        <TooltipStyled
          role='tooltip'
          anchorSelect={`#${id}`}
          content={typeof value === 'string' ? value : undefined}
        />
      )}
      {typeof value !== 'string' && valueTooltip && (
        <TooltipStyled role='tooltip' anchorSelect={`#${id}`}>
          {valueTooltip}
        </TooltipStyled>
      )}
    </CardStyled>
  )
}

export default MetricCard
