import Cookies from 'js-cookie'

const SESSION_TOKEN = 'sessionToken'

export function getSessionToken() {
  return Cookies.get(SESSION_TOKEN)
}
export function setSessionToken(token: string, expiration: string | Date) {
  Cookies.set(SESSION_TOKEN, token, { expires: new Date(expiration) })
}
export function deleteSessionToken() {
  Cookies.remove(SESSION_TOKEN)
}
export const isAuthenticated = () => !!getSessionToken()
