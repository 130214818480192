import { Button, Group, Modal, Stack, TextInput } from '@mantine/core'
import { useField } from '@mantine/form'
import { isNil, trim } from 'lodash'

import styles from './styles.module.css'
import { SaveQueryModalProps } from './types'
import {
  useCreateSavedFilter,
  useUpdateSavedFilterName,
} from '../../../../queries/savedFilters'
import { searchParamsToSavedFilter } from '../../utils/savedFilters'
import { useDataExplorerSearchParams } from '../../utils/searchParams'

const UpsertSavedFilterModal = ({
  id,
  name = '',
  onClose,
}: SaveQueryModalProps) => {
  const [searchParams] = useDataExplorerSearchParams()
  const createSavedFilter = useCreateSavedFilter()
  const updateSavedFilterName = useUpdateSavedFilterName()
  const isNewQuery = isNil(id)

  const nameField = useField({
    initialValue: name,
    validate: (value) => (!trim(value) ? 'Name is required' : null),
    validateOnBlur: true,
  })

  const onCloseHook = () => {
    nameField.reset()
    onClose()
  }

  const saveQuery = async () => {
    if (await nameField.validate()) {
      return
    }
    const name = trim(nameField.getValue())
    if (isNewQuery) {
      createSavedFilter.mutate({
        name,
        query: searchParamsToSavedFilter(searchParams),
      })
    } else {
      updateSavedFilterName.mutate({
        id,
        name,
      })
    }

    onCloseHook()
  }

  const gainsightPrefix = `data-explorer-${
    isNewQuery ? 'new' : 'edit'
  }-saved-filter-modal`

  return (
    <Modal
      opened={true}
      onClose={onCloseHook}
      title={id ? `Rename '${name}'` : 'Save Filter'}
      className={styles.modal}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          void saveQuery()
        }}
      >
        <Stack gap='md'>
          <TextInput
            {...nameField.getInputProps()}
            size='md'
            label='Name'
            data-autofocus
          />

          <Group justify='flex-end'>
            <Button
              c='black'
              data-gainsight-id={`${gainsightPrefix}-cancel-button`}
              onClick={onCloseHook}
              size='compact-md'
              variant='transparent'
            >
              Cancel
            </Button>

            <Button
              size='md'
              type='submit'
              data-gainsight-id={`${gainsightPrefix}-save-button`}
            >
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default UpsertSavedFilterModal
