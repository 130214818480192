import styled from '@emotion/styled'
import { UilTimes } from '@iconscout/react-unicons'
import AngleDownIcon from '@iconscout/react-unicons/icons/uil-angle-down'
import {
  CheckIcon,
  Flex,
  Group,
  Mark,
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
  useMantineTheme,
} from '@mantine/core'

import classes from './Select.module.css'

type SelectProps = {
  gainsightTagId?: string
  /* Indicates the field has been modified by rendering a colored dot next to it */
  edited?: boolean
  /* Position of edit dot from top of field */
  editDotTop?: string
} & MantineSelectProps

const SelectContent = styled.div`
  position: relative;
`

/* a red dot to indicate edited status */
const Marker = styled(Mark)`
  display: none;
  &[data-edited='true'] {
    display: block;
  }
  position: absolute;
  left: 3px;
  top: 3px;
  width: 6px;
  height: 6px;
  border-radius: 10px;
`

const renderSelectOption: MantineSelectProps['renderOption'] = ({
  option,
  checked,
}) => (
  <Group gap='sm'>
    {checked ? (
      <CheckIcon stroke='1.5' color='currentColor' opacity='0.4' size={12} />
    ) : (
      <div style={{ width: 12 }} />
    )}
    {option.label}
  </Group>
)

const Select = ({
  clearable = true,
  editDotTop,
  edited,
  fz = 14,
  gainsightTagId,
  nothingFoundMessage = 'No options',
  size = 'md',
  onChange,
  ...rest
}: SelectProps) => {
  const theme = useMantineTheme()
  const shouldShowClear = clearable && edited

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation()
    onChange?.('', { value: '', label: '' })
  }

  const renderRightSection = () => (
    <Flex align='center' style={{ cursor: 'pointer' }}>
      {shouldShowClear ? <UilTimes onClick={handleClear} /> : <AngleDownIcon />}
    </Flex>
  )

  return (
    <SelectContent>
      <MantineSelect
        className={classes.select}
        classNames={{ option: classes.optionSelector }}
        clearable={clearable}
        comboboxProps={{
          width: 'fit-content',
          position: 'bottom-start',
          offset: 4,
        }}
        data-gainsight-id={gainsightTagId}
        fz={fz}
        nothingFoundMessage={nothingFoundMessage}
        size={size}
        rightSection={renderRightSection()}
        rightSectionPointerEvents={shouldShowClear ? 'auto' : 'none'}
        rightSectionWidth={30}
        radius='sm'
        renderOption={renderSelectOption}
        maxDropdownHeight='13.25rem'
        styles={{
          dropdown: { zIndex: theme.other.zIndex.dropdown },
          input: {
            color:
              rest.value === ''
                ? 'var(--mantine-color-placeholder)'
                : 'inherit',
          },
        }}
        onChange={onChange}
        {...rest}
      />
      <Marker color='red' data-edited={edited} top={editDotTop} />
    </SelectContent>
  )
}

export default Select
