import axios from 'axios'
import _, { isEmpty, isNil } from 'lodash'

import { MetadataParams, ResourceMetaResponse } from './types'
import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import { handleAxiosError, queryStringify } from '../../api/utils'

export const fetchResourceMetadata = async (queryParams: MetadataParams) => {
  const { customerId, resourceType, ...rest } = queryParams
  const filteredQuery = _({ ...rest })
    .omitBy(isNil)
    .omitBy(isEmpty)
    .valueOf()

  const query = queryStringify(filteredQuery)
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/metadata/${resourceType}?${decodeURIComponent(
    query
  )}`

  return axios
    .get<ResourceMetaResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}
