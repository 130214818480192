import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { sortBy, sum } from 'lodash'
import { Moment } from 'moment'

import { filterParamsToQueryStr } from './utils'
import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import { ImperialUnits } from '../../components/mantine/Quantity/types'
import {
  Grouping,
  ItemUsage,
  Resolution,
  ResourceType,
} from '../../ducks/types'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import { DataExplorerFilterParams, UUID } from '../../pages/DataExplorer/types'
import { filterDemoCircuitData } from '../../utils/demo'
import { enhanceAxiosError } from '../utils/enhanceAxiosError'

export type UsageDataResponse = {
  data: Array<ItemUsage>
  from: string
  to: string
  grouping: Grouping
  resolution: Resolution
  measurementTypes: Array<string>
  units: Array<ImperialUnits>
  ts: Array<string>
}

const fetchUsageData = (
  customerId: UUID,
  params: DataExplorerFilterParams,
  programStartDate?: Moment
) => {
  const queryStr = filterParamsToQueryStr(params, programStartDate)
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/usage/${
    params.resourceType
  }?${queryStr}`

  return axios
    .get<UsageDataResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => {
      filterDemoCircuitData(data)
      return { groups: data.data, ts: data.ts, units: data.units }
    })
    .catch(enhanceAxiosError)
}

export type EnhancedGroupData = ReturnType<typeof enhanceGroupUsageData>[0]
const enhanceGroupUsageData = (data: ItemUsage[], resourceType: ResourceType) =>
  data.map((group) => ({
    ...group,
    usage: group.activeEnergy ?? group.volume,
    kWUsage:
      resourceType == ResourceType.ELECTRICITY
        ? group?.activeEnergy?.map((value) => value * 4) // convert 15min of kWh to kW
        : undefined,
    totalUsage: sum(group.activeEnergy ?? group.volume),
  }))

export const useResourceUsageData = (
  params: DataExplorerFilterParams,
  programStartDate?: Moment,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {}
  const { currentCustomerId } = useCurrentCustomerStore()
  return useQuery({
    queryKey: [
      'resourceUsage',
      params.resourceType,
      currentCustomerId,
      { params },
    ],
    queryFn: () => fetchUsageData(currentCustomerId, params, programStartDate),
    enabled: !!currentCustomerId && !!params.resourceType && enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: ({ groups, ts, units }) => ({
      groups: sortBy(
        enhanceGroupUsageData(groups, params.resourceType),
        'totalUsage'
      ).reverse(),
      ts,
      units,
    }),
  })
}
