import { Stack, Title as MantineTitle, TitleProps } from '@mantine/core'
import { isValidElement } from 'react'
import styled from 'styled-components'

import ArrowBackIcon from './Icons/ArrowBackIcon'

interface TSProps {
  Actions?: React.ComponentType | JSX.Element
  actionsProps?: any
  backgroundColor?: string
  border?: boolean
  handleBackNavigation?: (() => void) | null
  titleProps?: any
  subTitleProps?: any
  Title: string | React.ComponentType
  SubTitle?: string | React.ComponentType | undefined
  marginBottom?: string
  padding?: string
}

export const HeaderWrapperStyled = styled.div<{
  border: boolean
  backgroundColor: string
  marginBottom: string
  padding: string
  [key: string]: any
}>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.background};
  border-bottom: ${({ border }) => (border ? '1px solid #E0E0E0' : 0)};
  padding: ${({ padding }) => (padding ? padding : '20px 24px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

const TitleWrapperStyled = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.fontMain};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};

  &,
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  svg {
    cursor: pointer;
    font-size: 20px;
    margin-right: 12px;
  }
`

const ActionsWrapperStyled = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
`

const PageHeader = ({
  Actions,
  actionsProps = {},
  border = true,
  backgroundColor = '',
  handleBackNavigation = null,
  Title,
  titleProps = {},
  SubTitle,
  subTitleProps = {},
  marginBottom = '',
  padding = '',
  ...props
}: TSProps & TitleProps) => {
  return (
    <HeaderWrapperStyled
      backgroundColor={backgroundColor}
      border={border}
      marginBottom={marginBottom}
      padding={padding}
      {...props}
    >
      <Stack gap={0}>
        <TitleWrapperStyled>
          {!!handleBackNavigation && (
            <div onClick={handleBackNavigation}>
              <ArrowBackIcon />
            </div>
          )}
          {typeof Title === 'string' ? (
            <h1>{Title}</h1>
          ) : (
            <Title {...titleProps} />
          )}
        </TitleWrapperStyled>
        <>
          {SubTitle &&
            (typeof SubTitle === 'string' ? (
              <MantineTitle order={3} size='h4'>
                {SubTitle}
              </MantineTitle>
            ) : (
              <SubTitle {...subTitleProps} />
            ))}
        </>
      </Stack>
      <ActionsWrapperStyled>
        {isValidElement(Actions)
          ? Actions
          : Actions &&
            typeof Actions === 'function' && <Actions {...actionsProps} />}
      </ActionsWrapperStyled>
    </HeaderWrapperStyled>
  )
}

export default PageHeader
