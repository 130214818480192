import _, { isEmpty, isNil } from 'lodash'
import { Moment } from 'moment'

import { getDateRange, getRequestRange, queryStringify } from '../../api/utils'
import { Grouping } from '../../ducks/types'
import {
  DataExplorerFilterParams,
  ParsedDataExplorerSearchParams,
  UUID,
} from '../../pages/DataExplorer/types'

export const filterParamsToQueryStr = (
  params: DataExplorerFilterParams,
  programStartDate: Moment | undefined
) => {
  const { startDate, endDate } = getDateRange(
    params.fromDate,
    params.toDate,
    params.period,
    programStartDate
  )

  const query = {
    ...params,
    ...getRequestRange(startDate, endDate),
    ...customGroupingToPayload(params.grouping),
    categoryValueIds: customFiltersToCategoryValuesUrlParams(params),
  }

  const queryFiltered = _(query)
    .omitBy(isNil)
    .omitBy(isEmpty)
    .omitBy((_, k: string) => k.startsWith('categoryValue-'))
    .omit('period', 'selectedSiteIds', 'options')
    .valueOf()
  const queryStr = decodeURIComponent(queryStringify(queryFiltered))
  return queryStr
}

export const customFiltersToCategoryValuesUrlParams = (
  params: ParsedDataExplorerSearchParams
) => {
  const categoryValue = _(params)
    .pickBy((_, k) => k.startsWith('categoryValue-'))
    .values()
    .flatten()
    .value()
  return categoryValue.length ? categoryValue : undefined
}

export const customGroupingToPayload = (
  grouping: Grouping | UUID | undefined
) => {
  if (grouping && !Object.values(Grouping).includes(grouping as Grouping)) {
    return {
      groupingId: grouping,
      grouping: Grouping.CATEGORY_VALUE,
    }
  } else {
    return { grouping }
  }
}
