import { Provider } from '@rollbar/react'
import { ReactNode } from 'react'
import Rollbar from 'rollbar'

import { getUserId, getUsername } from '../api/utils'

const rollbarConfig = {
  accessToken: import.meta.env.VITE_APP_ROLLBAR_DENALI_TOKEN,
  enabled: import.meta.env.MODE !== 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: import.meta.env.VITE_SHA1,
        source_map_enabled: true,
      },
    },
    environment: import.meta.env.VITE_APP_DEPLOY_ENV,
    source_map_enabled: true,
    server: {
      root: '../../',
      branch: import.meta.env.VITE_GIT_BRANCH,
    },
    code_version: import.meta.env.VITE_SHA1,
    guess_uncaught_frames: true,
    person: {
      id: getUserId(),
      email: getUsername(),
    },
  },
}

const RollbarProvider = ({ children }: { children: ReactNode }) => {
  return <Provider config={rollbarConfig}>{children}</Provider>
}

const rollbar = new Rollbar(rollbarConfig)

export { rollbar }

export default RollbarProvider
