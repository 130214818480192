import _, { isEqual, omit } from 'lodash'
import { useMemo } from 'react'

import {
  customFiltersToCategoryValuesUrlParams,
  customGroupingToPayload,
} from '../../../queries/resourceUsage/utils'
import { useSavedFilters } from '../../../queries/savedFilters'
import { SavedFilter } from '../../../queries/savedFilters/types'
import { omitNilEmpty } from '../../../utils/functional'
import { DateTimeStr, ParsedDataExplorerSearchParams } from '../types'
import useActiveFilters from './filterParams'
import { useSearchParamsToFilters } from './searchParams'

const removeTimeFromDatetime = (date?: DateTimeStr) => date?.split('T')[0]

const omittedKeys = [
  'buildingSystem',
  'circuit',
  'equipment',
  'meter',
  'panel',
  'site',
  'categoryValue',
  'categoryValueIds',
  'groupingId',
]
export const savedFilterToSearchParams = (
  query: SavedFilter['parameters']['query']
): ParsedDataExplorerSearchParams => {
  const customCategories = _(query.categoryValueIds)
    .groupBy(({ parentId }) => parentId)
    .mapKeys((_, k) => `categoryValue-${k}`)
    .mapValues((v) =>
      v.map(({ parentId, childId }) => `${parentId}:${childId}`)
    )
    .value()
  const searchParams = {
    ...omit(query, omittedKeys),
    fromDate: removeTimeFromDatetime(query.fromDate),
    toDate: removeTimeFromDatetime(query.toDate),
    grouping: query.groupingId ?? query.grouping,
    ...customCategories,
  }
  return omitNilEmpty(searchParams) as ParsedDataExplorerSearchParams
}

export const searchParamsToSavedFilter = (
  searchParams: ParsedDataExplorerSearchParams
): SavedFilter['parameters']['query'] => {
  const filteredParams = _(searchParams)
    .omit('measurementTypes', 'categoryValue')
    .omitBy((_, k) => k.startsWith('categoryValue-'))
    .value()
  const query = {
    ...filteredParams,
    categoryValueIds: customFiltersToCategoryValuesUrlParams(searchParams),
    ...customGroupingToPayload(searchParams.grouping),
  }

  return omitNilEmpty(query)
}

export const useActiveSavedFilter = () => {
  const { data: savedFilters = [] } = useSavedFilters()
  const [activeFilters] = useActiveFilters()
  const searchParamsToFilters = useSearchParamsToFilters()
  const activeSavedFilter = useMemo(
    () =>
      savedFilters.find((query) => {
        const filterQuery = searchParamsToFilters(
          savedFilterToSearchParams(query.parameters.query)
        )
        return isEqual(filterQuery, activeFilters)
      }),
    [activeFilters, savedFilters, searchParamsToFilters]
  )

  return activeSavedFilter
}
