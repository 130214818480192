import { TSSiteContractResponse, TSSiteResponse } from '../../ducks/sites'

export const site: TSSiteResponse = {
  id: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
  display: 'WC1-127: 1543 Innovation Drive - Silicon Valley - CA',
  address: {
    address1: '1543 Innovation Drive',
    city: 'Silicon Valley',
    state: 'CA',
    postalCode: '94025',
    country: 'US',
    timezone: 'America/Los_Angeles',
  },
  electricUtilityRate: 0.2023,
  currencyCode: 'USD',
  ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
  lat: 37.4529598,
  lng: -122.1817252,
  locale: 'en_US',
  name: null,
  source: 'REDAPTIVE',
  squareFootage: 1,
  timezone: 'America/Los_Angeles',
  active: true,
  ingestionDataStart: '2021-04-05T00:00:00',
  validName: 'WC1-127: 1543 Innovation Drive - Silicon Valley - CA',
  siteShifts: [],
  meterCounts: {
    electricity: 6,
    naturalGas: 2,
    water: 0,
  },
  ingestionDataStartElectricity: '',
  ingestionDataStartNaturalGas: '',
  ingestionDataStartWater: '',
}

export const siteContractMockData: TSSiteContractResponse = {
  siteId: 'cb9ff883-e0b4-4201-8cf5-20ff44477b35',
  contractDTOList: [
    {
      id: 'dba3b1ba-63f9-40c3-90ee-f3e2043f2827',
      opportunityId: 'R00006595',
      energyCommitment: 1587579.0,
      energyRate: 0.1596,
      utilityRate: 0.168,
      monthlyBlock: 22049.71,
      resourceUnit: 'kWh',
      type: 'LIGHTING',
    },
  ],
}

export default site
