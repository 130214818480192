import { formatNumberWithCustomFormatting } from '../../../utils'
import { siteIdMask } from '../../../utils/demoSiteIdMask'
import {
  TSBenchmarkingDataMapped,
  TSEnergyStarLastMonthData,
  TSEnergyStarSitesCountMapped,
} from '../types'

const siteEuiValues = [45.5, 67.2, 32.8, 78.1, 55.4]
const sourceEuiValues = [52.3, 71.8, 38.4, 82.5, 61.2]
const nationalMedianSiteEuiValues = [35.2, 42.8, 28.9, 45.6, 38.7]
const nationalMedianSourceEuiValues = [58.4, 65.2, 51.7, 72.3, 63.8]
const energyStarScores = [75, 82, 68, 90, 85]
const ghgEmissions = [245.6, 312.8, 178.4, 425.2, 289.7]

export const demoUserBenchmarkingMapper = (
  mappedData: TSBenchmarkingDataMapped[],
  unitFormatPreference: string
): { mappedData: TSBenchmarkingDataMapped[] } => {
  const checkValueAndFormat = (value: number) => {
    if (value) {
      return formatNumberWithCustomFormatting(
        value,
        unitFormatPreference
      ) as unknown as number
    }
    return value
  }

  const demoMaskedSites = Object.values(siteIdMask)
    .filter(({ isWpt }) => isWpt)
    .sort((b, a) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

  const newMappedData = mappedData
    .slice(0, demoMaskedSites.length)
    .map((item, i) => {
      const siteEui = siteEuiValues[i % siteEuiValues.length]
      const sourceEui = sourceEuiValues[i % sourceEuiValues.length]
      const nationalMedianSiteEui =
        nationalMedianSiteEuiValues[i % nationalMedianSiteEuiValues.length]
      const nationalMedianSourceEui =
        nationalMedianSourceEuiValues[i % nationalMedianSourceEuiValues.length]
      const nationalMedianSiteEuiDiff = parseFloat(
        (nationalMedianSiteEui - siteEui).toFixed(2)
      )
      const nationalMedianSourceEuiDiff = parseFloat(
        (nationalMedianSourceEui - sourceEui).toFixed(2)
      )
      const energyStarScore = energyStarScores[i % energyStarScores.length]
      const nationalMedianEnergyStarSource = energyStarScore - 10
      const eligibleForCertification = i % 2 === 0 ? 'Yes' : 'No'
      const totalGhgEmissions = ghgEmissions[i % ghgEmissions.length]

      return {
        ...item,
        siteName: demoMaskedSites[i].name,
        siteEui: checkValueAndFormat(siteEui).toString(),
        sourceEui: checkValueAndFormat(sourceEui).toString(),
        nationalMedianSiteEui: checkValueAndFormat(
          nationalMedianSiteEui
        ).toString(),
        nationalMedianSourceEui: checkValueAndFormat(
          nationalMedianSourceEui
        ).toString(),
        nationalMedianSiteEuiDiff: checkValueAndFormat(
          nationalMedianSiteEuiDiff
        ).toString(),
        nationalMedianSourceEuiDiff: checkValueAndFormat(
          nationalMedianSourceEuiDiff
        ).toString(),
        energyStarScore: checkValueAndFormat(energyStarScore).toString(),
        nationalMedianEnergyStarSource: checkValueAndFormat(
          nationalMedianEnergyStarSource
        ).toString(),
        totalGhgEmissions: checkValueAndFormat(totalGhgEmissions).toString(),
        eligibleForCertification,
      }
    })

  return { mappedData: newMappedData }
}

export const demoUserMonthlySummaryPayload = (
  apiData: TSEnergyStarLastMonthData
): TSEnergyStarLastMonthData => {
  return {
    ...apiData,
    enabledSites: 18,
    sitesSubmittedForElectricity: 10,
    totalSitesForElectricity: 13,
    sitesSubmittedForWater: 10,
    totalSitesForWater: 11,
    sitesSubmittedForGas: 8,
    totalSitesForGas: 10,
    sitesNotSubmittedSuccessfully: 14,
  }
}

export const demoUserCustomerMappedPayload =
  (): TSEnergyStarSitesCountMapped => {
    return {
      isEnabled: true,
      siteMappingEnabled: true,
      sitesCount: {
        electric: 13,
        water: 11,
        gas: 10,
      },
      energyStarCustomerId: 1234,
    }
  }
