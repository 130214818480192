const usageData = (fromDate: string, toDate: string) => {
  const startDate = new Date(fromDate).getTime()
  const endDate = new Date(toDate).getTime()
  const diffTime = Math.abs(endDate - startDate)
  const intervals = Math.floor(diffTime / (15 * 60 * 1000)) + 1

  return {
    grouping: 'equipment',
    from: fromDate,
    to: toDate,
    resolution: '15min',
    resourceType: 'electricity',
    measurementTypes: ['rmsVoltage'],
    ts: Array.from({ length: intervals }, (_, i) => {
      const date = new Date(startDate)
      date.setMinutes(date.getMinutes() + i * 15)
      return date.toISOString()
    }),
    data: [
      {
        groupName: 'HVAC System A',
        groupId: 'hvac-a-123',
        rmsVoltage: Array.from({ length: intervals }, () =>
          Number((Math.random() * (245 - 235) + 235).toFixed(2))
        ),
        calcSpend: Number((Math.random() * 5000 + 1000).toFixed(2)),
      },
      {
        groupName: 'Lighting Panel 1',
        groupId: 'light-1-456',
        rmsVoltage: Array.from({ length: intervals }, () =>
          Number((Math.random() * (245 - 235) + 235).toFixed(2))
        ),
        calcSpend: Number((Math.random() * 3000 + 800).toFixed(2)),
      },
      {
        groupName: 'Manufacturing Line 2',
        groupId: 'mfg-2-789',
        rmsVoltage: Array.from({ length: intervals }, () =>
          Number((Math.random() * (245 - 235) + 235).toFixed(2))
        ),
        calcSpend: Number((Math.random() * 8000 + 2000).toFixed(2)),
      },
    ],
  }
}

export default usageData
