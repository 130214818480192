export const customerStatusMockData = {
  integrationEnabled: true,
  enabledElectricSites: 8,
  enabledWaterSites: 3,
  enabledNaturalGasSites: 6,
  energyStarCustomerId: 98765,
  siteMappingEnabled: true,
}

export const benchmarkingMockData = [
  {
    id: '4b8c1e2f-9a3d-5b7c-8d4e-6f2a1b3c4d5e',
    created: '2023-12-18T17:23:47.721+00:00',
    createdBy: null,
    modified: '2023-12-18T17:23:47.721+00:00',
    modifiedBy: null,
    siteId: '7d9e5f3a-2b1c-4d8e-9f6a-7b3c5d4e8f2a',
    siteName: 'Sample Location A',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-07',
    siteEui: 1.3,
    sourceEui: 2.2,
    nationalMedianSiteEui: 3.2,
    nationalMedianSourceEui: 4.2,
    nationalMedianSiteEuiDiff: 5.2,
    nationalMedianSourceEuiDiff: 6.2,
    energyStarScore: 65,
    nationalMedianEnergyStarSource: 42,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.15,
  },
  {
    id: '1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
    created: '2023-12-19T09:45:22.512+00:00',
    createdBy: null,
    modified: '2023-12-19T09:45:22.512+00:00',
    modifiedBy: null,
    siteId: '2b3c4d5e-6f7g-8h9i-0j1k-2l3m4n5o6p7',
    siteName: 'Sample Location B',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-08',
    siteEui: 1.5,
    sourceEui: 2.7,
    nationalMedianSiteEui: 3.7,
    nationalMedianSourceEui: 4.7,
    nationalMedianSiteEuiDiff: 5.7,
    nationalMedianSourceEuiDiff: 6.7,
    energyStarScore: 72,
    nationalMedianEnergyStarSource: 48,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.18,
  },
  {
    id: '3c4d5e6f-7g8h-9i0j-1k2l-3m4n5o6p7q8',
    created: '2023-12-19T10:15:33.721+00:00',
    createdBy: null,
    modified: '2023-12-19T10:15:33.721+00:00',
    modifiedBy: null,
    siteId: '4d5e6f7g-8h9i-0j1k-2l3m-4n5o6p7q8r9',
    siteName: 'Sample Location C',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-09',
    siteEui: 1.6,
    sourceEui: 3.2,
    nationalMedianSiteEui: 4.2,
    nationalMedianSourceEui: 5.2,
    nationalMedianSiteEuiDiff: 6.2,
    nationalMedianSourceEuiDiff: 7.2,
    energyStarScore: 75,
    nationalMedianEnergyStarSource: 52,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.22,
  },
]

export const meterMappingMockData = [
  {
    siteName: 'Sample Site 1',
    rdpSiteId: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
    esPropertyName: 'Property 1',
    esPropertyId: 222222,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Sample Site 2',
    rdpSiteId: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Sample Site 3',
    rdpSiteId: 'b2c3d4e5-f6g7-8901-2345-67890abcdef1',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Sample Site 4',
    rdpSiteId: 'c3d4e5f6-g7h8-9012-3456-7890abcdef12',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Sample Site 5',
    rdpSiteId: 'd4e5f6g7-h8i9-0123-4567-890abcdef123',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
]

export const pendingPropertiesData = [
  {
    created: '2024-10-15T13:04:03.331Z',
    createdBy: 'john.doe@example.com',
    modified: '2024-10-17T11:14:33.246Z',
    modifiedBy: 'jane.smith@example.com',
    id: 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6',
    accountId: 123456,
    propertyId: 78901234,
    propertyName: 'Sample Property A',
    street: '456 Main St',
    city: 'Springfield',
    state: 'IL',
    postalCode: '62701',
    country: 'US',
    isActive: false,
    isAccepted: true,
  },
  {
    created: '2024-10-22T09:41:21.211Z',
    createdBy: 'bob.wilson@example.com',
    modified: null,
    modifiedBy: null,
    id: 'b2c3d4e5-f6g7-h8i9-j0k1-l2m3n4o5p6q7',
    accountId: 234567,
    propertyId: 89012345,
    propertyName: 'Sample Property B',
    street: '789 Oak Ave',
    city: 'Riverside',
    state: 'CA',
    postalCode: '92501',
    country: 'US',
    isActive: false,
    isAccepted: true,
  },
]

export const submissionsMockData = [
  {
    id: 'c3d4e5f6-g7h8-i9j0-k1l2-m3n4o5p6q7r8',
    customerId: 'd4e5f6g7-h8i9-j0k1-l2m3-n4o5p6q7r8s9',
    siteId: 'e5f6g7h8-i9j0-k1l2-m3n4-o5p6q7r8s9t0',
    energyStarMeterId: 'f6g7h8i9-j0k1-l2m3-n4o5-p6q7r8s9t0u1',
    reportMonthlyCycle: '2024-10',
    customerName: 'Sample Customer A',
    siteName: 'Sample Building 1',
    resourceType: 'naturalGas',
    dataSource: 'redaptiveMeter',
    status: 'SUCCESS',
    dataAvailability: 1,
    currentMonthEnergyConsumption: 82345.67,
    deltaEnergyConsumption: -8.123456789,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: '2024-11-07T13:56:47.296903',
    errorStatusCode: 201,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 34567890,
    modifiedBy: 'alice.johnson@example.com',
    modified: '2024-11-07T13:56:49.023595',
    propertyName: 'Sample Complex A',
    propertyId: 45678901,
  },
  {
    id: 'g7h8i9j0-k1l2-m3n4-o5p6-q7r8s9t0u1v2',
    customerId: 'h8i9j0k1-l2m3-n4o5-p6q7-r8s9t0u1v2w3',
    siteId: 'i9j0k1l2-m3n4-o5p6-q7r8-s9t0u1v2w3x4',
    energyStarMeterId: 'j0k1l2m3-n4o5-p6q7-r8s9-t0u1v2w3x4y5',
    reportMonthlyCycle: '2024-06',
    customerName: 'Sample Customer B',
    siteName: 'Sample Building 2',
    resourceType: 'electricity',
    dataSource: 'redaptiveMeter',
    status: 'SUCCESS',
    dataAvailability: 1,
    currentMonthEnergyConsumption: 76543.21,
    deltaEnergyConsumption: -14.987654321,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: '2024-11-07T13:56:47.175974',
    errorStatusCode: 201,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 56789012,
    modifiedBy: 'charlie.brown@example.com',
    modified: '2024-11-07T13:56:49.009582',
    propertyName: 'Sample Complex B',
    propertyId: 67890123,
  },
  {
    id: 'k1l2m3n4-o5p6-q7r8-s9t0-u1v2w3x4y5z6',
    customerId: 'l2m3n4o5-p6q7-r8s9-t0u1-v2w3x4y5z6a7',
    siteId: 'm3n4o5p6-q7r8-s9t0-u1v2-w3x4y5z6a7b8',
    energyStarMeterId: 'n4o5p6q7-r8s9-t0u1-v2w3-x4y5z6a7b8c9',
    reportMonthlyCycle: '2024-07',
    customerName: 'Sample Customer C',
    siteName: 'Sample Building 3',
    resourceType: 'water',
    dataSource: 'redaptiveMeter',
    status: 'NOT_SUBMITTED',
    dataAvailability: 0.95,
    currentMonthEnergyConsumption: 78901.23,
    deltaEnergyConsumption: -12.345678901,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: null,
    errorStatusCode: null,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 78901234,
    modifiedBy: 'david.miller@example.com',
    modified: '2024-11-10T23:41:31.133802',
    propertyName: 'Sample Complex C',
    propertyId: 89012345,
  },
  {
    id: 'o5p6q7r8-s9t0-u1v2-w3x4-y5z6a7b8c9d0',
    customerId: 'p6q7r8s9-t0u1-v2w3-x4y5-z6a7b8c9d0e1',
    siteId: 'q7r8s9t0-u1v2-w3x4-y5z6-a7b8c9d0e1f2',
    energyStarMeterId: 'r8s9t0u1-v2w3-x4y5-z6a7-b8c9d0e1f2g3',
    reportMonthlyCycle: '2024-08',
    customerName: 'Sample Customer D',
    siteName: 'Sample Building 4',
    resourceType: 'electricity',
    dataSource: 'redaptiveMeter',
    status: 'SUCCESS',
    dataAvailability: 1,
    currentMonthEnergyConsumption: 81234.56,
    deltaEnergyConsumption: -9.87654321,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: '2024-11-07T13:56:47.072233',
    errorStatusCode: 201,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 90123456,
    modifiedBy: 'emma.wilson@example.com',
    modified: '2024-11-07T13:56:48.946507',
    propertyName: 'Sample Complex D',
    propertyId: 12345678,
  },
  {
    id: 's9t0u1v2-w3x4-y5z6-a7b8-c9d0e1f2g3h4',
    customerId: 't0u1v2w3-x4y5-z6a7-b8c9-d0e1f2g3h4i5',
    siteId: 'u1v2w3x4-y5z6-a7b8-c9d0-e1f2g3h4i5j6',
    energyStarMeterId: 'v2w3x4y5-z6a7-b8c9-d0e1-f2g3h4i5j6k7',
    reportMonthlyCycle: '2024-09',
    customerName: 'Sample Customer E',
    siteName: 'Sample Building 5',
    resourceType: 'electricity',
    dataSource: 'redaptiveMeter',
    status: 'SUCCESS',
    dataAvailability: 1,
    currentMonthEnergyConsumption: 79012.34,
    deltaEnergyConsumption: -11.111111111,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: '2024-11-07T13:56:46.969843',
    errorStatusCode: 201,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 23456789,
    modifiedBy: 'frank.thomas@example.com',
    modified: '2024-11-07T13:56:49.027616',
    propertyName: 'Sample Complex E',
    propertyId: 34567890,
  },
  {
    id: 'w3x4y5z6-a7b8-c9d0-e1f2-g3h4i5j6k7l8',
    customerId: 'x4y5z6a7-b8c9-d0e1-f2g3-h4i5j6k7l8m9',
    siteId: 'y5z6a7b8-c9d0-e1f2-g3h4-i5j6k7l8m9n0',
    energyStarMeterId: 'z6a7b8c9-d0e1-f2g3-h4i5-j6k7l8m9n0p1',
    reportMonthlyCycle: '2024-10',
    customerName: 'Sample Customer F',
    siteName: 'Sample Building 6',
    resourceType: 'electricity',
    dataSource: 'redaptiveMeter',
    status: 'SUCCESS',
    dataAvailability: 1,
    currentMonthEnergyConsumption: 77654.32,
    deltaEnergyConsumption: -13.579246801,
    previousMonthEnergyConsumption: 89765.43,
    submissionDate: '2024-11-11T00:03:27.95224',
    errorStatusCode: 201,
    meterName: null,
    dataAggregation: 'wholeSite',
    meterDataVerifiedDate: '2024-02-01T00:00:00',
    espmStartdate: '2024-03-01T00:00:00',
    espmMeterId: 45678901,
    modifiedBy: 'grace.parker@example.com',
    modified: '2024-11-11T00:03:30.164801',
    propertyName: 'Sample Complex F',
    propertyId: 56789012,
  },
]
