import { NotificationData, notifications } from '@mantine/notifications'
import { floor } from 'lodash'

import styles from './notifications.module.css'

const showCustomNotification = ({
  message,
  duration = floor(message.length / 52) * 2000 + 6000, // 6s for one line of text and +2s for each additional line,
  ...rest
}: {
  message: string
  duration?: number | boolean
} & NotificationData) => {
  notifications.show({
    message,
    className: styles.notification,
    autoClose: duration,
    ...rest,
  })
}

export default showCustomNotification
