import { TSCustomersResponse } from '../../ducks/customers'

export const TWENTY_FOUR_HOUR_FITNESS_ID =
  '9cdd900e-473c-4f79-98de-1a873bb52ead'
const customers: TSCustomersResponse = [
  {
    customerLogoUrl:
      'https://logo-denali-dev.s3.amazonaws.com/r1_logo/40368e2d-b776-43ba-9046-63de2e206732/Bridge_industrial-bridge_industrial.png',
    id: TWENTY_FOUR_HOUR_FITNESS_ID,
    name: 'Global Fitness Solutions',
    notifyDataBehind: true,
    validName: 'Global Fitness Solutions',
    initials: 'GFS',
    logoBackgroundColor: '#ff0000',
  },
  {
    id: '5ff953f9-eb68-4d65-b8b8-591a4be92ef5',
    name: 'TechnoScience Industries',
    notifyDataBehind: true,
    validName: 'TechnoScience Industries',
    initials: '',
    logoBackgroundColor: '#ff0000',
  },
  {
    id: '730af796-9fc9-473c-8868-f5b37d40c73c',
    name: 'Summit Enterprises Ltd.',
    notifyDataBehind: true,
    validName: 'Summit Enterprises Ltd.',
    initials: 'SE',
    logoBackgroundColor: '',
  },
  {
    id: '6c0520ca-9b2d-47b4-a372-5b1266c5a5ff',
    name: 'Pacific Retail Group',
    notifyDataBehind: true,
    validName: 'Pacific Retail Group',
    initials: 'PR',
    logoBackgroundColor: '',
  },
  {
    id: 'b2ba5c89-611e-4bc1-8f56-47a20e3ff398',
    name: 'Vertex Systems',
    notifyDataBehind: true,
    validName: 'Vertex Systems',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '168f736f-23d5-4d69-869f-3d162bb465ac',
    name: 'Quantum Healthcare',
    notifyDataBehind: true,
    validName: 'Quantum Healthcare',
    initials: 'QH',
    logoBackgroundColor: '',
  },
  {
    id: '6944d15e-2fdf-4ce6-8af0-8b4219fd8f10',
    name: 'Atlas Medical Network',
    notifyDataBehind: true,
    validName: 'Atlas Medical Network',
    initials: 'AM',
    logoBackgroundColor: '',
  },
  {
    id: 'abbbf24a-6bfb-453b-8b17-a8eb71ba7a44',
    name: 'Evergreen Energy Solutions',
    notifyDataBehind: true,
    validName: 'Evergreen Energy Solutions',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '651ec833-6756-4ef0-a90d-7b97fb0c5543',
    name: 'Pinnacle Sports Management',
    notifyDataBehind: true,
    validName: 'Pinnacle Sports Management',
    initials: 'PSM',
    logoBackgroundColor: '',
  },
  {
    id: '6abe4bc1-14f3-4874-b2df-4965a9db26ac',
    name: 'Cascade Regional Hospital',
    notifyDataBehind: true,
    validName: 'Cascade Regional Hospital',
    initials: '',
    logoBackgroundColor: '#9DBD79',
  },
  {
    id: '13e72426-509e-44cf-9fe1-fc15df4fd9b8',
    name: 'Stellar Food Services',
    externalId: '3341',
    notifyDataBehind: true,
    validName: 'Stellar Food Services',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '7c494449-0ddd-4680-a896-ccbef2fac0d3',
    name: 'Phoenix Pharmaceuticals',
    notifyDataBehind: true,
    validName: 'Phoenix Pharmaceuticals',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'e8df0b2e-6b30-42a6-beb8-6cbdac052807',
    name: 'Nexus Digital Solutions',
    notifyDataBehind: true,
    validName: 'Nexus Digital Solutions',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '202009c6-a567-42e0-b554-839393b6e320',
    name: 'Orion Communications',
    notifyDataBehind: true,
    validName: 'Orion Communications',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    name: 'Synthesis Labs Inc',
    notifyDataBehind: true,
    validName: 'Synthesis Labs Inc',
    initials: '',
    logoBackgroundColor: '',
  },
]

export default customers
