import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { defaultHeaders, denaliApiBaseUrl, gcApiBaseUrl } from '../api'
import { getUserId } from '../api/utils'
import { TSCustomerResponse, TSCustomersResponse } from '../ducks/customers'
import { useCurrentCustomerStore } from '../globalState/currentCustomer'
import customersMockData from '../mockData/customers'
import { filterDemoCustomers } from '../utils/demo'
import { isVariantActive } from '../utils/variants'
import { EnhancedAxiosError } from './utils/enhanceAxiosError'

type RDPCustomerSalesforceMap = {
  rdpCustomerId: string
  salesforceCustomerId: string
}

const fetchCustomers = () => {
  if (isVariantActive('mock')) {
    return Promise.resolve(filterDemoCustomers(customersMockData))
  }
  const url = `${denaliApiBaseUrl()}/customers?page=0&size=10000`
  return axios
    .get<TSCustomersResponse>(url, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => filterDemoCustomers(data))
}

const fetchSalesforceIds = () => {
  const url = `${gcApiBaseUrl()}/customers/mappings`
  return axios
    .get<RDPCustomerSalesforceMap[]>(url, {
      headers: defaultHeaders(),
    })
    .then(({ data }) => data)
}

const sharedOptions = ({ queryName }: { queryName: string[] }) => ({
  queryKey: ['customers', ...queryName],
  staleTime: 1000 * 20, // Cache for 20Sec,
})

export const useCustomers = (
  options?: Partial<
    UndefinedInitialDataOptions<
      TSCustomerResponse[],
      EnhancedAxiosError,
      TSCustomerResponse[],
      string[]
    >
  >
) => {
  const currentUserId = getUserId()
  return useQuery({
    queryFn: fetchCustomers,
    ...sharedOptions({
      queryName: [currentUserId],
    }),
    ...options,
  })
}

export const useCurrentCustomerSalesforceId = () => {
  const { currentCustomerId } = useCurrentCustomerStore()

  return useQuery({
    queryFn: fetchSalesforceIds,
    ...sharedOptions({
      queryName: ['salesforce-id', currentCustomerId],
    }),
    enabled: !!currentCustomerId,
    select: (data) => {
      return data
        .filter((item) => item.rdpCustomerId === currentCustomerId)
        .map((item) => item.salesforceCustomerId)
    },
  })
}
