import { TSSitesResponse } from '../../ducks/sites'

const sites: TSSitesResponse = [
  {
    id: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    display: 'WC1-127: 1543 Innovation Drive - Silicon Valley - CA',
    address: {
      address1: '1543 Innovation Drive',
      city: 'Silicon Valley',
      state: 'CA',
      postalCode: '94025',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.2023,
    currencyCode: 'USD',
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 37.4529598,
    lng: -122.1817252,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-04-05T00:00:00',
    validName: 'WC1-127: 1543 Innovation Drive - Silicon Valley - CA',
    siteShifts: [],
    meterCounts: {
      electricity: 6,
      naturalGas: 2,
      water: 0,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '14953a76-df93-486f-a968-d76c2107ddd8',
    display: 'TX2-826: 4750 Tech Boulevard - Austin - TX',
    address: {
      address1: '4750 Tech Boulevard',
      city: 'Austin',
      state: 'TX',
      postalCode: '78701',
      country: 'US',
      timezone: 'America/Chicago',
    },
    electricUtilityRate: 0.1477,
    currencyCode: 'USD',
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 30.2672,
    lng: -97.7431,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3535,
    timezone: 'America/Chicago',
    active: true,
    ingestionDataStart: '2020-03-16T00:00:00',
    validName: 'TX2-826: 4750 Tech Boulevard - Austin - TX',
    siteShifts: [],
    meterCounts: {
      electricity: 5,
      naturalGas: 0,
      water: 3,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'eaaaea2d-fe00-4d50-ac5b-92efdd56c8ca',
    display: 'IL3-213: 789 Lake Shore Drive - Chicago - IL',
    address: {
      address1: '789 Lake Shore Drive',
      city: 'Chicago',
      state: 'IL',
      postalCode: '60601',
      country: 'US',
      timezone: 'America/Chicago',
    },
    electricUtilityRate: 0.2194,
    currencyCode: 'USD',
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 41.8781,
    lng: -87.6298,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    timezone: 'America/Chicago',
    active: true,
    ingestionDataStart: '2021-03-31T00:00:00',
    validName: 'IL3-213: 789 Lake Shore Drive - Chicago - IL',
    siteShifts: [],
    meterCounts: {
      electricity: 8,
      naturalGas: 0,
      water: 0,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '8160bb59-1d4e-45a3-856e-61fd578a455a',
    display: 'FL4-259: 350 Palm Avenue - Miami - FL',
    address: {
      address1: '350 Palm Avenue',
      city: 'Miami',
      state: 'FL',
      postalCode: '33101',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1884,
    currencyCode: 'USD',
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 25.7617,
    lng: -80.1918,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3574,
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-04-05T00:00:00',
    validName: 'FL4-259: 350 Palm Avenue - Miami - FL',
    siteShifts: [],
    meterCounts: {
      electricity: 0,
      naturalGas: 2,
      water: 4,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '9cc371a1-9270-48a3-892c-5d3ba99f70e5',
    display: 'WA8-176: 925 Pine Street - Seattle - WA',
    address: {
      address1: '925 Pine Street',
      city: 'Seattle',
      state: 'WA',
      postalCode: '98101',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.1831,
    currencyCode: 'USD',
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 47.6062,
    lng: -122.3321,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 4500,
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-06-17T00:00:00',
    validName: 'WA8-176: 925 Pine Street - Seattle - WA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
]

export default sites
